<template>
  <DelayHydration>
    <UiHeader
      :header="header"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      class="text-primary"
    />
    <div class="relative -mr-8 xl:mr-0 xl:hidden">
      <UiSliderHorizontal
        class="swiper-main"
        classes="swiper-main scrollbar-primary"
      >
        <template
          v-for="item in props.services"
          :key="item.id"
        >
          <UiServiceBox
            class="w-full last:mr-8 xl:last:mr-0"
            :icon="elementLayout === 'with-icon' ? item.content.icon : null"
            :title="item.content.header"
            :subtitle="item.content.description"
            :button="{
              btnText: item.content.linkText,
              link: item.content.link.href,
              target: item.content.link?.target,
            }"
            :image="{
              src:
                (item.content?.file?.[0]?.cropVariants?.desktop?.publicUrl
                  ?? '')
                || (item.content?.file?.[0]?.publicUrl ?? ''),
            }"
            :image-mobile="{
              src:
                (item.content?.file?.[0]?.cropVariants?.mobile?.publicUrl
                  ?? '')
                || (item.content?.file?.[0]?.publicUrl ?? ''),
            }"
          />
        </template>
      </UiSliderHorizontal>
    </div>
    <div
      v-if="Array.isArray(props.services) && props.services.length"
      class="hidden gap-x-6 pb-5 pl-8 pt-4 xl:-m-5 xl:flex xl:p-5"
    >
      <template
        v-for="item in props.services"
        :key="item.id"
      >
        <LazyUiServiceBox
          class="w-1/2 last:mr-8 xl:last:mr-0"
          :icon="elementLayout === 'with-icon' ? item.content.icon : null"
          :title="item.content?.header ?? ''"
          :subtitle="item.content?.description ?? ''"
          :button="{
            btnText: item.content.linkText,
            link: item.content.link.href,
          }"
          :image="{
            src:
              (item.content?.file?.[0]?.cropVariants?.desktop?.publicUrl
                ?? '')
              || (item.content?.file?.[0]?.publicUrl ?? ''),
          }"
          :image-mobile="{
            src:
              (item.content?.file?.[0]?.cropVariants?.mobile?.publicUrl
                ?? '')
              || (item.content?.file?.[0]?.publicUrl ?? ''),
          }"
        />
      </template>
    </div>
  </DelayHydration>
</template>

<script setup lang="ts">
import type { T3Appearance, T3File, T3Link } from '@t3headless/nuxt-typo3'
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import type { UiHeaderProps } from '@ui/components/UiHeader/useUiHeader'
import LazyUiServiceBox from '@ui/components/UiServiceBox/UiServiceBox.vue'
import type { CropVariants } from '@cms/types/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<Props>()

interface Service {
  id: number
  colPos: number
  type: string
  categories?: unknown
  appearance: T3Appearance & { whereToShow: string }
  content: {
    header?: string
    description?: string
    file: Array<T3File & { cropVariants?: CropVariants }>
    link: T3Link
    icon: string
    linkText: string
  }
}

interface Props extends UiHeaderProps {
  services: Service[]
  elementLayout: 'without-icon' | 'with-icon'
}
</script>
